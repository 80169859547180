import React, {useEffect} from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import tulsaHome from "../images/01-TULSA-PRO_Hero-Image.png"
import tulsaProCropped from "../images/02_How-TULSA-procedure-prostate-treatment-works-scaled_cropped.jpg"
import princenthal from "../images/05_Robert-Princenthal-Circle.png"
import tulsaVsHifu from "../images/TULSA-PRO-vs-HIFU-blog-post-01.png"
import careCoordinator from "../images/07_Care-Coordinator-circle.png"
import physicianIcon from "../images/Physician-Icon.png"
import HeroImageContainer from "../components/heroImage"
import HearFromYouForm from "../components/forms/hearfromyou"
import westhillstulsa from "../images/03_LP-Tulsa-Procedure-Prostate-Website.jpg"
import parkcentraltulsa from "../images/04_AZ-PC-Tulsa-Procedure-Prostate-Website.jpg"
import octulsa from "../images/RadNet-Orange-County-Saddleback-Valley-Radiology.jpg"


let title = `TULSA Procedure | Prostate Cancer Treatment | California & Arizona`
let desc = `The TULSA Procedure is an incision free prostate treatment for prostate cancer. A HIFU alternative (high intensity focused ultrasound) with locations in Los Angeles California and Phoenix Arizona.`

export const Tulsa = ({data}) => {
    useEffect(() => {
        // Existing functionality for script
        const script = document.createElement('script');
        script.innerHTML = `function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof(url) != 'undefined') {
                    window.location = url;
                }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-561731194/qsyZCNuZke4BEPqs7YsC',
                'event_callback': callback
            });
            return false;
        }`;
        document.body.appendChild(script);
    
       // Accordion functionality
       const accordions = document.getElementsByClassName("accordion");
       for (let i = 0; i < accordions.length; i++) {
           accordions[i].addEventListener("click", function() {
               this.classList.toggle("active");
               const panel = this.nextElementSibling;
               if (panel.style.maxHeight) {
                   panel.style.maxHeight = null;
               } else {
                   // Use scrollHeight to ensure you get the full height of the content, even if it changes
                   panel.style.maxHeight = panel.scrollHeight + "px";
               }
           });
       }

       // Cleanup function to remove event listeners
       return () => {
           for (let i = 0; i < accordions.length; i++) {
               // Remove event listener by cloning the element
               const oldElement = accordions[i];
               const newElement = oldElement.cloneNode(true);
               oldElement.parentNode.replaceChild(newElement, oldElement);
           }
       };
    }, []);
    const handleLearnMoreClick = (event) => {
        event.preventDefault(); // Prevent the default anchor link behavior
    
        const tulsaStartSection = document.getElementById('tulsaStart'); // Get the target section
        if (!tulsaStartSection) return; // Guard clause in case the element doesn't exist
    
        const offsetTop = tulsaStartSection.offsetTop - 10; // Calculate the top position and subtract 100 pixels
    
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Layout page={title} desc={desc} pageClass={"Tulsa"}>
                <div className="inside-page tulsa pb6">
                    <div className="hero">
                        <div className="overlay">
                            <div className="container-inner-xs">
                                <h1>The TULSA Procedure</h1>
                                <p>
                                    Transurethral Ultrasound Ablation: <br/>
                                    A Better Option for Prostate Treatment
                                </p>
                                <a href="#tulsaStart" onClick={handleLearnMoreClick}>Learn More</a>
                            </div>
                        </div>
                        <HeroImageContainer imageUrl={tulsaHome} />
                        <div id="tulsaStart"></div>
                    </div>
                    <div className="container-inner pt4">
                        <h2 class="text-center">The TULSA Procedure Experience</h2>
                        <div className="row">
                            <div className="col v-align-middle">
                                <div>
                                    <p>
                                        An incision-free solution for prostate disease, the TULSA 
                                        Procedure system combines real-time Magnetic Resonance 
                                        Imaging (MRI) with robotically-driven directional thermal 
                                        ultrasound and closed-loop temperature feedback control 
                                        software, delivering transurethral prostate tissue ablation of 
                                        whole-gland or partial prostate tissue. The system is designed 
                                        to be customizable, physician prescribed, and predictable. In 
                                        addition to being incision-free, it is also radiation-free 
                                        ablation that simultaneously protects the urethra and rectum 
                                        with water cooling, which preserves men’s functional abilities.
                                    </p>
                                    <p>
                                        The procedure is FDA approved and is performed on an outpatient basis in state-of-the-art operating rooms with fully monitored recovery suites.
                                    </p>
                                </div>
                            </div>
                            <div className="col align-center v-align-middle">
                                <div>
                                    <img className="tulsa-img-cropped" src={tulsaProCropped} alt="TULSA Procedure Doctor Patient"/>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "6.25% 0 6.25% 0", position: "relative", margin:"0 auto", maxWidth:"900px"}}>
                            <h2 className="text-center">Kieth's Life-Changing Journey with the TULSA Procedure</h2>
                            <iframe 
                                style={{width:"100%", minHeight:"500px"}}
                                src="https://www.youtube.com/embed/zC1WhFORESg" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                    <div className="gray-bg pt4">
                        
                            <h2 class="text-center">RadNet TULSA Pro Centers in CA & AZ</h2>
                            <div className="tulsa-locations">
                                <div>
                                    <img className="" src={westhillstulsa} alt="West Hills TULSA Location"/>
                                    <div className="inner-location">
                                        <h4>Liberty Pacific Advanced Imaging West Hills</h4>
                                        <p>
                                            23115 Sherman Pl<br/>
                                            West Hills CA, 91307
                                        </p>
                                        <hr/>
                                        <p>
                                            <b>Phone:</b> (602) 277-4111<br/>
                                            <b>Fax:</b> (602) 277-1333
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img className="" src={parkcentraltulsa} alt="West Hills TULSA Location"/>
                                    <div className="inner-location">
                                        <h4>Arizona Diagnostic Radiology Park Central</h4>
                                        <p>
                                            3115 N. 3rd Ave Ste 140<br/>
                                            Phoenix AZ, 85013
                                        </p>
                                        <hr/>
                                        <p>
                                            <b>Phone:</b> (818) 251-9811<br/>
                                            <b>Fax:</b> (818) 342-0303
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img className="" src={octulsa} alt="Orange County TULSA Location"/>
                                    <div className="inner-location">
                                        <h4>WaveImaging Saddleback</h4>
                                        <p>
                                            23961 Calle De La Magdelena
                                            Suite 130<br/>
                                            Laguna Hills, CA 92653
                                        </p>
                                        <hr/>
                                        <p>
                                            <b>Phone:</b> (949)-855-4301<br/>
                                            <br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="container-inner pt4">
                        <div className="flex-l mt5">
                            <div className="tc tl-l mr5-l w-50-l">
                                <img src={princenthal} alt="Doctor Princenthal"/>
                            </div>
                            <div className="w-75-l">
                                <p style={{lineHeight: `2rem`}}>
                                    <span className="baskerville f2 lh-copy">&#8220;</span>
                                    TULSA Pro is a revolutionary method of focal therapy. The energy is delivered trans
                                    urethral, under MRI real time thermal mapping, for the most accurate,
                                    rapid, and effective whole gland ablation therapy. Their TACT trial, TULSA-PRO Ablation Clinical Trial, has proven results of excellent PSA level reduction, lowest side effects to bowel/bladder, and over 80% cancer control, which is comparable to conventional treatments of surgery and radiation.
                                    <span className="baskerville f3 lh-copy">&#8221;
                                    </span>
                                </p>
                                <p className="" style={{fontWeight:`800`, fontStyle:`italic`}}>
                                    - Dr. Robert Princenthal
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tulsa-benefits-bg">
                    <div className="container-inner pt4 tulsa-benefits-inner">
                        <h2 className="text-center">Benefits of The TULSA Procedure</h2>
                        <p className="text-center">
                            <b>In addition to being an incision-free procedure, the TULSA <br/>
                            Procedure offers the following benefits to patients:</b>
                        </p>
                        <div className="row">
                            <div className="col">
                                <ul>
                                    <li>Radiation free.</li>
                                    <li>Minimal to no pain, speedy recovery.</li>
                                    <li>Real-time MRI imaging.</li>
                                    <li>Outpatient procedure - requires no hospital stay</li>
                                </ul>
                            </div>
                            <div className="col">
                                <ul>
                                    <li>Reduced post-operative complications and costs.</li>
                                    <li>MR Suite is significantly less expensive than an operating room.</li>
                                    <li>Decreased risk of side effects such as urinary incontinence and erectile dysfunction</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-inner pt5 faqs">
                    <h2 class="text-center">Frequently Asked Questions</h2>
                    <button className="accordion">Is the TULSA Procedure an inpatient or outpatient procedure?</button>
                    <div className="panel">
                        <p>The TULSA Procedure is an outpatient procedure.  You will go home the same day you have your treatment.</p>
                    </div>

                    <button className="accordion">How long does the procedure take?</button>
                    <div className="panel">
                        <p>The TULSA Procedure is performed in a single session that takes a few hours. You will be under general anesthesia during your treatment but usually, the entire process takes about 5 hours.</p>
                    </div>

                    <button className="accordion">How do I know I am eligible for TULSA Procedure?</button>
                    <div className="panel">
                        <p>To make sure you qualify for TULSA Procedure, our team of Radiologists and Urologists will need to review all recent biopsy and MRI reports as well as your clinical history. They will need to see your MRI images as well, which you will need to mail in on a CD to the following address: Rolling Oaks Radiology – Attn: TULSA Program Care Coordinator 415 Rolling Oaks Drive, Suite 160 Thousand Oaks, CA 91361. Our Care Coordinator will reach out to you within one week to let you know your status.</p>
                    </div>

                    <button className="accordion">What exactly is the TULSA Procedure?</button>
                    <div className="panel">
                        <p>TULSA Procedure is a transurethral prostate tissue ablation system that combines real-time Magnetic Resonance Imaging (MRI) with robotically-driven directional thermal ultrasound to deliver predictable physician-prescribed ablation of whole-gland or partial prostate tissue. It is incision free and radiation free.</p>
                    </div>

                    <button className="accordion">Is the TUSLA Procedure painful?</button>
                    <div className="panel">
                        <p>The TULSA Procedure is a minimally invasive procedure and requires no incision. Most patients recover quickly and can return to their everyday life after a few days.</p>
                    </div>

                    <button className="accordion">What happens to the prostate after the TULSA Procedure?</button>
                    <div className="panel">
                        <p>Following the TULSA Procedure treatment, the prostate shrinks and can continue to shrink for 12 months thereafter.</p>
                    </div>

                    <button className="accordion">During the TULSA Procedure treatment, is the entire prostate destroyed?</button>
                    <div className="panel">
                        <p>The TULSA Procedure is a transurethral prostate tissue ablation system that combines real-time Magnetic Resonance Imaging (MRI) with robotically-driven directional thermal ultrasound to deliver predictable physician-prescribed ablation of whole-gland or partial prostate tissue. The amount of prostate ablated is up to the discretion of the treating physician and is fully customizable to your individual needs.</p>
                    </div>

                    <button className="accordion">Is there a chance the cancer could return at some point after treatment?</button>
                    <div className="panel">
                        <p>As with many cancers and treatments, there is a possibility of recurrence. If your cancer were to return, TULSA Procedure treatment is repeatable.</p>
                    </div>

                    <button className="accordion">What is the recovery time following TULSA Procedure treatment?</button>
                    <div className="panel">
                        <p>Patients typically return to their daily activities (including work) within a couple of days following treatment, and then back to baseline (urinary/bowel) quality of life within three months.</p>
                    </div>

                    <button className="accordion">What is my follow up after TULSA Procedure treatment?</button>
                    <div className="panel">
                        <p>A few days after your TULSA Procedure treatment, you will see your urologist in his/her office for a follow-up visit. At this appointment, your catheter will be removed and various labs will be performed. You will have a second follow-up appointment 6 weeks later for a general prostate and wellness check.</p>
                    </div>

                    <button className="accordion">Will my private insurance or Medicare cover the cost of treatment?</button>
                    <div className="panel">
                        <p>TULSA PRO is currently not covered by health insurers or Medicare. Patients are responsible for the entire cost of treatment. We are sensitive to the financial cost involved and offer an all-inclusive price for the procedure (not including lab fees). We do not add any undisclosed fees. Our Care Coordinator can provide you with detailed pricing information.</p>
                    </div>

                    <button className="accordion">Are there financing options for the TULSA Procedure?</button>
                    <div className="panel">
                        <p>Financing is available through Care Credit, which provides a line of credit for health and wellness expenses. Care Credit allows you to manage out-of-pocket healthcare expenses at over 200,000 participating providers nationwide, including RadNet. You can apply for credit and receive a decision in minutes.</p>
                    </div>
                </div>
                <div className="tulsa-hifu mt-4">
                    <h2 className="text-center">TULSA PROCEDURE vs HIFU</h2>
                    <div>
                        <img src={tulsaVsHifu} alt="TULSA vs HIFU"></img>
                        <p>High Intensity Focused Ultrasound (HIFU) is similar to TULSA PRO in that it is a non-invasive procedure that targets cancer cells directly. Both procedures use thermal ultrasound to ablate prostate tissue, but they differ significantly in their delivery methods.</p>
                        <p>HIFU uses an “outside-in” approach, meaning the ultrasound energy used to ablate prostate tissue comes from outside the prostate. Because the HIFU treatment requires a device inserted into the rectum, the heat waves must pass through the rectal wall in order to reach the particular prostate area.</p>
                        <p>Conversely, the TULSA Procedure inserts its device directly through the urethra, which is inside the prostate, hence, utilizing an “inside-out” approach. Inside the urethra, the device rotates, creating a heating pattern that travels toward the targeted prostate region from inside the prostate. The ability to ablate from inside-out means the TULSA procedure is completely incision free.</p>
                        <p>Unlike HIFU, TULSA is also administered in live-time with the use of MRI. This ensures that the procedure is controlled and predictable.</p>
                        <p>During the TULSA Procedure, a cooling device is inserted into the rectum which protects tissue from the ultrasound energy, while simultaneously, the transurethral device is also cooling the urethra. Actively cooling the urethra and the rectum helps preserve the patient’s natural functions and does not permit for any thermal energy to pass through the rectum.</p>
                        <p>Ultimately, The TULSA Procedure uses better guidance and control. It has immediate energy application feedback and actively cools non-target tissue.</p>
                    </div>
                </div>
                <div className="container-inner pt4">
                        <div className="flex-l mt5">
                            <div className="w-75-l">
                                <h2>TULSA Procedure Care<br/>Coordinator</h2>
                                <p>
                                    At RadNet, we're focused on ensuring better treatment and delivering 
                                    the best possible outcomes for every patient we treat. In addition to our 
                                    highly skilled staff of radiologists and technicians, every patient will work 
                                    with our Care Coordinator for the TULSA Procedure. The Care 
                                    Coordinator is designated to each TULSA Procedure patient and works 
                                    only with the TULSA Procedure. The Care Coordinator will be the first 
                                    person you hear from at RadNet, going over necessary requirements
                                    and exams. The Care Coordinator will answer all of your questions,
                                    make appointments, set up a calendar of events for you, and will
                                    guide you through the entire process.
                                </p>
                                <p>
                                    We want every one of our patients to have all the knowledge and 
                                    information he needs throughout the process and to know he is never 
                                    alone on his TULSA Procedure journey.
                                </p>
                            </div>
                            <div className="tc tl-l mr5-l w-50-l pt-50">
                                <img src={careCoordinator} alt="TULSA Care Coordinator"/>
                            </div>
                        </div>
                    </div>
                <div className="container-inner pt4">
                    <HearFromYouForm />
                </div>
                <div className="pre-footer">
                    <div className="mw9 center ph3-ns">
                        <div class="flex flex-wrap justify-between items-center ph2-ns">
                        <div class="fl w-20-l w-100 pa2 flex items-center justify-center">
                                <img src={physicianIcon} alt="TULSA Physician Icon"/>
                            </div>
                            <div class="fl w-80-l w-100 pa2 flex items-center justify-center">
                                <div>
                                    <h2><b>For Physicians</b></h2>
                                    <p>
                                        If you are a physician and you are interested in more information about the TULSA 
                                        Procedure, or you would like to train to administer the TULSA Procedure along with 
                                        one of our radiologists, please contact us via email at <a href="mailto:tulsaprostate@radnet.com"><b>tulsaprostate@radnet.com</b></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
     )
 }

 export const query = graphql`
    query TulsaQuery {
        allNodeTulsa(sort: {order: ASC, fields: created}) {
            nodes {
                title
                fields {
                    slug
                }
            }
        }
    }
 `

 export default Tulsa
